<template>
	<div class="">
		<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
			<div class="tableConTable" v-loading="loading">
				<div class="tableConTop">
					<el-row>
						<el-col :span="5" class="tableConTopLeft">
							<h3>
								<el-button type="primary" round icon="el-icon-back" size="mini" @click="$router.push({ name: 'WhCustomer' })">{{ $t('hytxs0000058') }}</el-button>
								<span class="tct_tit">{{ $t('hytxs0000059') }}</span>
								<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
									<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
								</el-tooltip>
							</h3>
						</el-col>
						<el-col :span="19" class="tableConTopRig">
							<!-- <el-button  @click="updatePartnerDataAction($event,$t('4e9fc68608c60999'))" type="danger" size="small" icon="el-icon-close">{{$t('i18nn_4f62f8d07bb4054f')}}</el-button> -->
							<!-- <span v-if="!isSel"> -->
							<!-- <el-button @click="addAction($event)" type="primary" size="small" icon="el-icon-plus">{{$t('c0246c55b9cac963')}}</el-button> -->

							<el-button @click="feeResetAction(null)" type="warning" size="small" icon="el-icon-s-operation">{{$t('i18nn_6a78da7fb21a7380')}}</el-button>
							<!-- <el-button @click="delAction($event,null)" type="danger" size="mini" icon="el-icon-minus">{{$t('e33c9b93c36fd250')}}</el-button> -->
							<!-- </span> -->
							<!--右边容器-->
							<!-- <slot name="topRight"></slot> -->
							<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
						</el-col>
					</el-row>
				</div>

				<div>
					<div class="filterCon">
						<!-- <el-row type="flex" justify="end"> -->
							<!-- <el-col :span="24"> -->
								<ul class="filterConList">
									<!-- <li>
                    <span>{{$t('i18nn_cabc5dc617eef30e')}}</span>

                    <el-date-picker
                      v-model="filterData.statisticsCurrentDate"
                      value-format="yyyy-MM-dd"
                      type="date"
                      size="small"
                      :placeholder="$t('68efabd029c860a9')"
                      @change="initData()"
                    ></el-date-picker>
                  </li> -->
									<!-- <li>
                    <span>{{$t('6cdece641436d7ab')}}</span>
                    <el-select filterable clearable :disabled="isSel" v-model="filterData.status" :placeholder="$t('2ad108ab2c560530')" size="small" style="width: 100px;" @change="initData()">
                      <el-option v-for="item in selectOption.statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </li> -->
									<!-- <li>
                    <span>{{$t('i18nn_6bfc5f83ed58481a')}}</span>
                    <el-input
                      type="text"
                      v-model="filterData.plNo"
                      size="small"
                      clearable
                      @keyup.enter.native="initData()"
                      maxlength="50"
                      :placeholder="$t('hytxs0000001')"
                      style="width: 180px;"
                    />
                  </li> -->
									<li>
										<span>{{ $t('hytxs0000060') }}</span>
										<cusSelFuzzy ref="cusSelFuzzy" @changeData="changCus"></cusSelFuzzy>
									</li>
									<li>
										<span>{{ $t('c944a6686d996ab3') }}</span>
										<!-- <el-select filterable clearable v-model="filterData.whNo" :placeholder="$t('2ad108ab2c560530')" size="small" style="width: 150px;" @change="initData()">
											<el-option v-for="item in selectOption.wh_no" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ item.companyName }}</span>
				<span class="sel_option_code" style="">{{ item.cusNo }}</span>
			</div></el-option>
										</el-select> -->
										<whNoSelect @changeData="changWhNo"></whNoSelect>
									</li>
									<li>
										<span class="red-required">{{ $t('i18nn_d42355e6aef72227') }}</span>
										<el-date-picker
											v-model="filterData.daterange"
											size="small"
											type="daterange"
											align="right"
											:clearable="false"
											unlink-panels
											range-separator="-"
											start-placeholder="start date"
											end-placeholder="end date"
											:picker-options="pickerOptions"
											format="yyyy-MM-dd"
											value-format="yyyy-MM-dd"
											@change="initData()"
										></el-date-picker>
									</li>
									<li>
										<span>{{$t('i18nn_158ecb8cbcabc069')}}</span>
										<el-select filterable clearable v-model="filterData.chargeBizType" size="small"
											:placeholder="$t('2ad108ab2c560530')" style="width: 100px;" @change="initData()">
											<el-option v-for="item in selectOption.wh_charge_biz_type" :key="item.code"
												:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
												<div class="sel_option_s1" style="">
													<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
													<span class="sel_option_code" style="">{{ item.code }}</span>
												</div>
											</el-option>
										</el-select>
									</li>
									<li>
										<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
										<el-input
											type="text"
											v-model="filterData.keyword"
											size="small"
											clearable
											@keyup.enter.native="initData()"
											maxlength="50"
											:placeholder="$t('custom_enterfuzzysearch')"
											style="width: 180px;"
										/>
									</li>
									<!-- <li>
                    <span>{{$t('209fdd8f47e20fbf')}}</span>
                    <el-input
                      type="text"
                      v-model="filterData.cusNo"
                      size="small"
                      clearable
                      @keyup.enter.native="initData()"
                      maxlength="50"
                      :placeholder="$t('hytxs0000001')"
                      style="width: 180px;"
                    />
                  </li> -->
									<!-- <li>
                    <span>{{$t('i18nn_e0c16ac094cc2bce')}}</span>
                    <el-input
                      type="text"
                      v-model="filterData.goodsSku"
                      size="small"
                      clearable
                      @keyup.enter.native="initData()"
                      maxlength="50"
                      :placeholder="$t('hytxs0000001')"
                      style="width: 180px;"
                    />
                  </li> -->
									<!-- <li>
                    <span>{{$t('i18nn_9eeccc6d6f1ff32f')}}</span>
                    <el-input
                      type="text"
                      v-model="filterData.goodsName"
                      size="small"
                      clearable
                      @keyup.enter.native="initData()"
                      maxlength="50"
                      :placeholder="$t('hytxs0000001')"
                      style="width: 180px;"
                    />
                  </li> -->
									<li>
										<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">{{ $t('1e7246dd6ccc5539') }}</el-button>
									</li>
								</ul>
							<!-- </el-col> -->
						<!-- </el-row> -->
					</div>
				</div>

				<el-table
					id="out-table2"
					ref="multipleTable"
					:data="tableData"
					stripe
					:border="true"
					:height="$store.state.tableMaxHeight2"
					@row-click="handleCurrentChange"
					@selection-change="handleSelectionChange"
					style="width: 100%"
					size="small"
				>
					<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('7b1c2b1adc920d9c')"></el-table-column>
					<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->

					<!-- "cusNo":$t('i18nn_64fed2851f79d875'),
                "packageNo":$t('i18nn_7b27c6b2a3f93412'),
                "goodsSku":$t('i18nn_e0c16ac094cc2bce'),
                "goodsImg":$t('i18nn_877c5a0e44a0eb07'),
                "goodsName":$t('i18nn_9eeccc6d6f1ff32f'),

                "weightUnit":$t('i18nn_8e01866868930d09'),
                "goodsWeight":$t('i18nn_ad4b0047e16d89ae'),

                "volumeUnit":$t('i18nn_57936a4767ded7f5'),
                "goodsLength":$t('0e60303b30d5d4b4'),
                "goodsWidth":$t('6cd31871f8528dd5'),
                "goodsHeight":$t('93c9ea4a0e52c641'),

                "goodsCount":$t('hytxs0000008') -->

					<!-- <el-table-column :label="$t('i18nn_8bdf7efaeff69d77')"> -->
					<!-- <el-table-column prop="statesName" :label="$t('6cdece641436d7ab')"></el-table-column> -->
					<el-table-column prop="cusNo" :label="$t('209fdd8f47e20fbf')"></el-table-column>
					<!-- <el-table-column prop="putWhNo" :label="$t('i18nn_3eb79cfe71bbae0b')"></el-table-column> -->
					<el-table-column prop="cusName" :label="$t('hytxs0000060')" show-overflow-tooltip></el-table-column>
					<!-- <el-table-column prop="putWhNo" :label="$t('594abd886182b662')" min-width="300">
            <template slot-scope="scope">
              <div>包装编号：{{ scope.row.packageNo }}</div>
              <div>产品代码：{{ scope.row.goodsSku }}</div>
              <div>产品名称：{{ scope.row.goodsName }}</div>

              <div>
                <el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg" @click="openBigImg($event, scope.row.goodsImg)">
                  <div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
                </el-image>
              </div>
            </template>
          </el-table-column> -->
					<el-table-column prop="feeTypeName" :label="$t('46c3f6e0f657e7a3')"></el-table-column>
					<el-table-column prop="feeAmt" :label="$t('d7315fb8114eb446')"></el-table-column>
					<el-table-column prop="feeDescription" :label="$t('4b107048a1f3cfd0')" min-width="160">
						<template slot-scope="scope">
							<div v-if="scope.row.feeDescriptionJson">
								<div>
									<span>{{ $t('3775920d44088b96') }}</span>
									:
									<span>{{ scope.row.feeDescriptionJson.putWhCount }}</span>
								</div>
								<div>
									<span>{{$t('i18nn_4d3765c415bb4462')}}</span>
									:
									<span>{{ scope.row.feeDescriptionJson.whAge }}</span>
								</div>
								<div>
									<span>{{ $t('96e86c7f83dd7561') }}</span>
									:
									<span>{{ scope.row.feeDescriptionJson.price }}&nbsp;{{ scope.row.feeDescriptionJson.whFeeTypeName }}</span>
								</div>
								
								<div>
									<span><span>{{$t('i18nn_748bca3d68c277f5')}}</span>(<span>{{$t('i18nn_3258bbe488af3e79')}}</span>)</span>
									:
									<span>{{ scope.row.feeDescriptionJson.calcCycle }}</span>
								</div>
								<div>
									<span>{{$t('i18nn_aa0fc2e0cf8a9331')}}</span>
									:
									<span>{{ scope.row.feeDescriptionJson.marksNos }}</span>
								</div>

								<div v-if="scope.row.feeDescriptionJson.remark">
									<span>{{ $t('15b3627faddccb1d') }}</span>
									:
									<span>{{ scope.row.feeDescriptionJson.remark }}</span>
								</div>
								
								
							</div>
						</template>
					</el-table-column>

					<el-table-column prop="feeDate" :label="$t('9bfcf061ccdd5c1c')"></el-table-column>

					<el-table-column prop="whNo" :label="$t('5acbec83efb27445')"></el-table-column>
					<el-table-column prop="place" :label="$t('hytxs0000035')"></el-table-column>

					<el-table-column prop="goodsSku" :label="$t('i18nn_e0c16ac094cc2bce')">
						<template slot-scope="scope">
							<div v-if="scope.row.feeDescriptionJson">
								<div>
									<span>{{ scope.row.feeDescriptionJson.goodsSku }}</span>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="goodsName" :label="$t('539728f5dd9cb065')">
						<template slot-scope="scope">
							<div v-if="scope.row.feeDescriptionJson">
								<div>
									<span>{{ scope.row.feeDescriptionJson.goodsName }}</span>
								</div>
								<div v-if="scope.row.feeDescriptionJson.goodsName!=scope.row.feeDescriptionJson.goodsNameEn">
									<span>{{ scope.row.feeDescriptionJson.goodsNameEn }}</span>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="vol" :label="$t('i18nn_0e240c5802d17fea')">
						<template slot-scope="scope">
							<div v-if="scope.row.feeDescriptionJson">
								<div>
									<span>{{ scope.row.feeDescriptionJson.vol }}</span>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="inWhDate" :label="$t('hytxs0000006')">
						<template slot-scope="scope">
							<div v-if="scope.row.feeDescriptionJson">
								<div>
									<span>{{ scope.row.feeDescriptionJson.inWhDate }}</span>
								</div>
							</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="goodsImg" :label="$t('efb573c2cb944147')">
						<template slot-scope="scope">
							<div v-if="scope.row.feeDescriptionJson">
								<div>
									<div>
										<el-image
											lazy
											style="width: auto; height: 40px"
											fit="contain"
											:src="scope.row.feeDescriptionJson.goodsImg"
											@click="openBigImg($event, scope.row.feeDescriptionJson.goodsImg)"
										>
											<div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
										</el-image>
									</div>
								</div>
							</div>
						</template>
					</el-table-column> -->
					<!-- <el-table-column prop="goodsId" label="GOODS_ID">
          </el-table-column> -->

					<!-- <el-table-column prop="goodsSku" :label="$t('i18nn_e0c16ac094cc2bce')">
            <template slot-scope="scope">
              <div>{{ scope.row.goodsSku }}</div>
            </template>
          </el-table-column> -->
					<!-- <el-table-column prop="goodsName" :label="$t('539728f5dd9cb065')">
            <template slot-scope="scope">
              <div>{{ scope.row.goodsName }}</div>
            </template>
          </el-table-column> -->
					<!-- <el-table-column prop="goodsImg" :label="$t('efb573c2cb944147')" align="center" width="100">
            <template slot-scope="scope">
              <div style="">
                <el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg" @click="openBigImg($event, scope.row.goodsImg)">
                  <div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="goodsCount" :label="$t('i18nn_bad53577db0da2d3')">
          </el-table-column>
          <el-table-column prop="pVolAll" :label="$t('hytxs0000010')">
          </el-table-column>
          <el-table-column prop="pWeightAll" :label="$t('hytxs0000009')">
          </el-table-column> -->
					<!-- <el-table-column prop="plCount" :label="$t('i18nn_0bd31bb65ba068e8')"> -->
					<!-- <template slot-scope="scope"> -->
					<!-- <el-input size="mini" :placeholder="$t('5a9aefbc03c778f7')" v-if="'add' == scope.row.dataFlag || 'edit' == scope.row.dataFlag" v-model="scope.row.goodsLength"></el-input> -->
					<!-- <el-input-number size="mini" v-model="scope.row.plCount" controls-position="right"  v-if="'add' == scope.row.dataFlag || 'edit' == scope.row.dataFlag"></el-input-number>

              <div v-else>{{ scope.row.plCount }}</div> -->

					<!-- <div>{{ scope.row.plCount }}</div> -->
					<!-- </template> -->
					<!-- </el-table-column> -->
					<!-- <el-table-column prop="realPlCount" :label="$t('i18nn_06c0936eb86ae31b')" min-width="100"> -->
					<!-- <template slot-scope="scope"> -->
					<!-- <div>{{ scope.row.realPlCount }}</div> -->
					<!-- <el-input-number
                size="mini"
                v-model="scope.row.realPlCount"
                controls-position="right"
                v-if="'add' == scope.row.dataFlag || 'edit' == scope.row.dataFlag"
              ></el-input-number> -->

					<!-- <div >{{ scope.row.realPlCount }}</div> -->
					<!-- </template> -->
					<!-- </el-table-column> -->

					<!-- <el-table-column prop="weightUnitName" :label="$t('i18nn_8e01866868930d09')">
          </el-table-column>
          <el-table-column prop="goodsWeight" :label="$t('i18nn_ad4b0047e16d89ae')">
          </el-table-column>

          <el-table-column prop="volumeUnitName" :label="$t('i18nn_57936a4767ded7f5')">
          </el-table-column>
          <el-table-column prop="goodsLength" :label="$t('0e60303b30d5d4b4')">
          </el-table-column>
          <el-table-column prop="goodsWidth" :label="$t('6cd31871f8528dd5')">
          </el-table-column>

          <el-table-column prop="goodsHeight" :label="$t('93c9ea4a0e52c641')">
          </el-table-column>


          <el-table-column prop="goodsCount" :label="$t('hytxs0000008')">
          </el-table-column>
          <el-table-column prop="shevlesCount" :label="$t('i18nn_ae34bf9aa8b77864')">
          </el-table-column>

          <el-table-column prop="goodsAllVol" :label="$t('hytxs0000010')">
          </el-table-column>
          <el-table-column prop="goodsAllWeight" :label="$t('hytxs0000009')">
          </el-table-column> -->
					<!-- <el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
          </el-table-column> -->

					<!-- <el-table-column prop="putWhNo" :label="$t('i18nn_a332a38ed3832d9d')"></el-table-column> -->

					<!-- <el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')"></el-table-column> -->

					<!-- <el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')"></el-table-column> -->

					<!-- <el-table-column :label="$t('93f5ca01b006206c')" width="150px" fixed="right">
            <template slot-scope="scope">
								<el-button @click="feeResetAction($event, scope.row)" type="warning" size="small" icon="el-icon-s-operation">{{$t('i18nn_eb6d28d29b20a456')}}</el-button>
            </template>
          </el-table-column> -->
				</el-table>
			</div>
			<div class="tableConPagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>

		<!-- 产品图片上传 -->
		<!-- <el-dialog :title="$t('i18nn_218ff32b6139e98b')" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="500px">
      <hyUpLoadImg
        ref="hyUpLoadImg1"
        :imgId="imgId"
        :imgwidth="200"
        :imgheight="200"
        :imgKey="'WhShelves'"
        :bucket="'huyi-oss-user'"
        :maxSizeMB="5"
        :size="'small'"
        :autoOrient="true"
        :autoResize="true"
        v-on:changeImgUrl="changeImgUrl1"
      >
        <div slot="imgDesc">
          <span style="display: inline-block; width: 200px; font-size: 14px; padding-bottom: 5px;">
            支持png、jpg、gif等图片格式;
            <br />
            大小不超过5M;
            <br />
          </span>
        </div>
      </hyUpLoadImg>
    </el-dialog> -->

		<!--查看图片-->
		<!-- <el-dialog :title="$t('2674282277c3714e')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
			<div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogImgVisible = false">{{ $t('4e9fc68608c60999') }}</el-button>
			</div>
		</el-dialog> -->

		<!--选择入库数据-->
		<!-- <el-dialog :title="$t('7fb6ada66a1fccca')" append-to-body :close-on-click-modal="false" :visible.sync="dialogSelVisible" width="1200px" top="0"> -->
		<!-- <div style=""> -->
		<!-- <img :src="imgUrlBigShow" width="auto" height="auto" /> -->
		<!-- <WhInGoods :isSel="true" @selectRow="selWhGoodsData"></WhInGoods> -->
		<!-- </div> -->
		<!-- <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogSelVisible = false">{{$t('4e9fc68608c60999')}}</el-button></div> -->
		<!-- </el-dialog> -->
		<!-- excel 导出排序 -->
		<whExcelCustom
			:openTime="excelOpenTime"
			:excelHead="excelHead"
			:excelData="expExcelData"
			:pagination="pagination"
			:excelName="excelName"
			:excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhFeeRecordPageList"
			:expHttpFilter="pageFilterData()"
		></whExcelCustom>
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	
// import { deepClone } from '@/utils/utils.js';
// import excelUtilsNew from '@/utils/excelUtilsNew.js';
import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
import whExcelCustom from '@/components/WarehouseCenter2/components/whExcelCustom.vue';
export default {
	components: {
		// WhInGoods
		cusSelFuzzy,
		whNoSelect,
		whExcelCustom
	},
	props: {
		// mobile:"",
		isSel: {
			default: function() {
				return false;
			},
			type: Boolean
		}
	},
	data() {
		return {
			//excel导出
			excelOpenTime: '',
			excelHead: [],
			expExcelData: [],
			excelName: '',
			excelOption: {},

			pickerOptions: this.$PickerDate.pickerOptions_2(),
			//上传图片
			// dialogFormVisible: false,
			// imgId: this.$store.getters.getUserInfo.id,
			// openRowData: {},
			// openRowIndex: {},

			//图片放大
			// dialogImgVisible: false,
			// imgUrlBigShow: '',

			//打开入库选择框
			dialogSelVisible: false,

			//选择
			currentSel: {},
			//表格数据
			//loading,表格数据
			loading: false,
			loading_load: false,
			//表格数据
			tableData: [],
			//缓存，原数据
			// tableDataCatch: [],

			//分页数据
			pagination: this.$Utils.defaultPagination(),
			//表格选择的数据
			multipleSelection: [],
			//查询，排序方式
			//"orderBy": "auditTime,fCon,goodsCon", //排序字段
			selectOption: {
				wh_no: [],
				wh_charge_biz_type:[],
				// statusList: [
				//   {
				//     value: '',
				//     label: this.$t('16853bda54120bf1')
				//   },
				//   {
				//     value: '1',
				//     label: this.$t('hytxs0000027')
				//   },
				//   {
				//     value: '2',
				//     label: this.$t('i18nn_87619aa3fd09e8c2')
				//   }
				// ]
			},

			filterData: {
				orderBy: 'create_time', //排序字段
				sortAsc: 'desc', //desc降序，asc升序

				// status: '',
				// plNo: '',
				daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime()+3600 * 1000 * 24)],
				// cusNo: '',
				userId: '',
				keyword: '',
				whNo: '',
				chargeBizType:''
				// goodsSku: '',
				// goodsName: ''
			}
		};
	},
	// watch: {
	//   $route(to, from) {
	//     if (this.$route.query && this.$route.query.cusNo) {
	//       this.filterData.cusNo = this.$route.query.cusNo;
	//     }
	//     this.initData();
	//   }
	// },
	activated() {
		if (this.$route.query && this.$route.query.userId) {
			this.filterData.userId = this.$route.query.userId;
			this.$nextTick(()=>{
				this.$refs.cusSelFuzzy.init(this.filterData.userId)
			})
		}
		this.initData();
	},
	//创建时
	created() {
		// if(this.isSel){
		//   this.filterData.status = '2';
		// }
		// if (this.$route.query && this.$route.query.cusNo) {
		//   this.filterData.cusNo = this.$route.query.cusNo;
		// }
		//  this.initData();
	},
	//编译挂载前
	mounted() {
		// this.getWhNoData();
		// this.getDicData();
		//数据字典
		getDicData(['wh_charge_biz_type'],
			(data)=>{
				this.selectOption.wh_charge_biz_type = data['wh_charge_biz_type'];
		});
	},
	methods: {
		initData() {
			//跳转到第一页
			this.pagination.current_page = 1;
			this.currentSel = {};
			this.multipleSelection = [];

			this.getPageData();
			this.$nextTick(() => {
				this.$refs.multipleTable.doLayout();
			});
			// 部门数据
			// this.getProvinceData();
			// this.getDetData("");
		},
		// formatDescJson(jsonStr) {
		// 	let objHtml = '';
		// 	try {
		// 		let obj = JSON.parse(jsonStr);
		// 		objHtml = ``;
		// 	} catch (e) {
		// 		//TODO handle the exception
		// 		console.log('err', e);
		// 	}
		// 	return objHtml;
		// },
		//导出 excel
		exportExcel() {
			let columns = [
				{
					title: this.$t('209fdd8f47e20fbf'),
					key: 'cusNo'
				},
				{
					title: this.$t('hytxs0000060'),
					key: 'cusName'
				},
				{
					title: this.$t('46c3f6e0f657e7a3'),
					key: 'feeTypeName'
				},
				{
					title: this.$t('d7315fb8114eb446'),
					key: 'feeAmt'
				},
				{
					title: this.$t('3775920d44088b96'),
					key: 'putWhCount',
					types: 'custom',
					formatter: function(row) {
						// <div v-if="scope.row.feeDescriptionJson">
						//   <div>
						//     <span>{{$t('3775920d44088b96')}}</span>
						//     <span>{{scope.row.feeDescriptionJson.putWhCount}}</span>
						//   </div>
						//   <div>
						//     <span>{{$t('96e86c7f83dd7561')}}</span>
						//     <span>{{scope.row.feeDescriptionJson.whFeeTypeName}}</span>
						//   </div>
						//   <div v-if="scope.row.feeDescriptionJson.remark">
						//     <span>{{$t('15b3627faddccb1d')}}</span>
						//     <span>{{scope.row.feeDescriptionJson.remark}}</span>
						//   </div>
						// </div>
						return row.feeDescriptionJson.putWhCount;
					}
				},
				{
					title: this.$t('i18nn_4d3765c415bb4462'),
					key: 'whAge',
					types: 'custom',
					formatter: function(row) {
						return row.feeDescriptionJson.whAge;
					}
				},
				{
					title: this.$t('96e86c7f83dd7561'),
					key: 'whFeeTypeName',
					types: 'custom',
					formatter: function(row) {
						return  row.feeDescriptionJson.price+' '+row.feeDescriptionJson.whFeeTypeName;
					}
				},
				{
					title: this.$t('i18nn_748bca3d68c277f5')+'('+this.$t('i18nn_3258bbe488af3e79')+')',
					key: 'calcCycle',
					types: 'custom',
					formatter: function(row) {
						return  row.feeDescriptionJson.calcCycle;
					}
				},
				{
					title: this.$t('i18nn_aa0fc2e0cf8a9331'),
					key: 'marksNos',
					types: 'custom',
					formatter: function(row) {
						return  row.feeDescriptionJson.marksNos;
					}
				},
				{
					title: this.$t('9bfcf061ccdd5c1c'),
					key: 'feeDate'
				},
				{
					title: this.$t('5acbec83efb27445'),
					key: 'whNo'
				},
				{
					title: this.$t('hytxs0000035'),
					key: 'place'
				},
				{
					title: this.$t('i18nn_e0c16ac094cc2bce'),
					key: 'goodsSku',
					types: 'custom',
					formatter: function(row) {
						return row.feeDescriptionJson.goodsSku;
					}
				},
				{
					title: this.$t('539728f5dd9cb065'),
					key: 'goodsName',
					types: 'custom',
					formatter: function(row) {
						return row.feeDescriptionJson.goodsName + ' ' + row.feeDescriptionJson.goodsNameEn;
					}
				},
				{
					title: this.$t('i18nn_0e240c5802d17fea'),
					key: 'vol',
					types: 'custom',
					formatter: function(row) {
						return row.feeDescriptionJson.vol;
					}
				},
				{
					title: this.$t('hytxs0000006'),
					key: 'inWhDate',
					types: 'custom',
					formatter: function(row) {
						return row.feeDescriptionJson.inWhDate;
					}
				},
				{
					title: this.$t('15b3627faddccb1d'),
					key: 'remark',
					types: 'custom',
					formatter: function(row) {
						return row.feeDescriptionJson.remark;
					}
				}
			];
			let Data = this.tableData;

			// let data_dom = JQ('#ex_table').find(".el-table__body");
			// let obj_key_img = {};
			// // console.log(data_dom.find("tr"));
			// data_dom.find("tr").each((index, domEle)=>{
			// 	// console.log("index",index);
			// 	let imgObj = JQ(domEle).find("td .cell img");
			// 	// console.log("imgObj",imgObj);
			// 	if(imgObj){
			// 		obj_key_img[imgObj.attr("code")] = imgObj.attr("src");
			// 	}
			// })
			// // console.log("obj_key_img",obj_key_img);
			// Data.forEach(item=>{
			// 	item.barcodeimg = obj_key_img[item.whNo + '-' + item.place];
			// })
			this.expExcelData = Data;
			this.excelHead = columns;
			this.excelName = 'WhFeeRecordList';
			this.excelOption = { height: 20 };
			this.excelOpenTime = new Date().getTime();

			// excelUtilsNew.exportExcel(columns, Data, 'WhFeeRecordList', { height: 20 });
		},
		//打开选择数据
		// openSelData(event, row, index) {
		//   event.stopPropagation();

		//   this.dialogSelVisible = true;
		//   this.openRowData = row;
		//   this.openRowIndex = index;
		//   // this.$nextTick(() => {
		//   //   this.$refs.hyUpLoadImg1.openFile();
		//   // });
		// },
		//选择数据后回调
		// selWhGoodsData(selRow) {
		//   this.dialogSelVisible = false;

		//   let row = this.openRowData;
		//   row.putWhNo = selRow.putWhNo;
		//   row.goodsSku = selRow.goodsSku;
		//   row.goodsImg = selRow.goodsImg;
		//   row.goodsName = selRow.goodsName;
		//   this.$set(this.tableData, this.openRowIndex, row);

		//   // this.openRowData = row;
		//   // this.openRowIndex = index;
		//   // this.$nextTick(() => {
		//   //   this.$refs.hyUpLoadImg1.openFile();
		//   // });
		// },

		//作为组件选择行数据
		selRow(event, row, index) {
			event.stopPropagation();
			this.$emit('selectRow', row);
		},
		//打开图片上传
		// openUpLoadImg(row, index) {
		//   this.dialogFormVisible = true;
		//   this.openRowData = row;
		//   this.openRowIndex = index;
		//   this.$nextTick(() => {
		//     this.$refs.hyUpLoadImg1.openFile();
		//   });
		// },
		//清除图片
		// clearUpLoadImg(row,index){
		//   row.goodsImg = '';
		//   this.$set(this.tableData, index, row);
		// },

		//图片改变
		// changeImgUrl1(childValue) {
		//   console.log('changeImgUrl1', childValue);
		//   // childValue就是子组件传过来的值

		//   let row = this.openRowData;
		//   row.goodsImg = childValue;
		//   this.$set(this.tableData, this.openRowIndex, row);
		//   this.dialogFormVisible = false;
		//   // this.$set(this.tableDataCatch, this.openRowIndex, row);
		//   // this.myForm.imgList = [childValue];
		// },

		//查看图片
		// openBigImg(event, imgsrc) {
		// 	event.stopPropagation();
		// 	console.log('openBigImg', imgsrc);
		// 	this.dialogImgVisible = true;
		// 	this.imgUrlBigShow = imgsrc;
		// },

		//选择行
		handleCurrentChange(row, event, column) {
			this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
		},
		//多选
		handleSelectionChange(val) {
			console.log(val);
			this.multipleSelection = val;
		},

		//新增
		// addAction() {
		//   console.log('addAction');
		//   if(!!this.tableData[0] && !this.tableData[0].id){
		//     this.$alert('请先删除或保存第一条新增数据。', this.$t('cc62f4bf31d661e3'), {
		//       type: 'warning',
		//
		//     });
		//     return;
		//   }
		//   this.tableData.unshift({
		//     dataFlag: 'add',

		//     // "cusNo":this.$t('i18nn_64fed2851f79d875'),
		//     // "packageNo":this.$t('i18nn_7b27c6b2a3f93412'),
		//     // "goodsSku":this.$t('i18nn_e0c16ac094cc2bce'),
		//     // "goodsImg":this.$t('i18nn_877c5a0e44a0eb07'),
		//     // "goodsName":this.$t('i18nn_9eeccc6d6f1ff32f'),
		//     // "weightUnit":"11",//this.$t('i18nn_8e01866868930d09'),//this.$t('i18nn_8e01866868930d09'),
		//     // "goodsWeight":"1.122",//this.$t('i18nn_ad4b0047e16d89ae'),
		//     // "volumeUnit":"22",//this.$t('i18nn_57936a4767ded7f5'),//this.$t('i18nn_57936a4767ded7f5'),
		//     // "goodsLength":"1",//长",
		//     // "goodsWidth":"2",//宽",
		//     // "goodsHeight":"3",//高",
		//     // "goodsCount":"123",//入库件数",

		//     // "id":"",
		//     putWhNo: '',
		//     goodsSku: '',
		//     goodsName: '',
		//     goodsImg: '',
		//     pShelveCount: '', //实际上架数",
		//     pShelveVolAll: '', //this.$t('i18nn_bb0b485a98f17aea'),
		//     pShelveWeightAll: '', //this.$t('i18nn_4157ea5a8c62064b'),
		//     placeNo: '' //this.$t('hytxs0000035')
		//   });
		// },
		//删除新增
		// delAddAction(event, row, index) {
		//   event.stopPropagation();
		//   console.log('delAddAction', index);
		//   this.tableData.splice(index, 1);
		// },
		//编辑
		// openEdit(event, row, index) {
		//   event.stopPropagation();
		//   console.log('openEdit', row);
		//   row.dataFlag = 'edit';
		//   this.$set(this.tableData, index, row);
		// },
		//取消编辑，还原数据
		// editRefreshAction(event, row, index) {
		//   event.stopPropagation();
		//   console.log('editRefreshAction', row);
		//   // row.dataFlag = '';
		//   console.log('old_row_data11', this.tableDataCatch);
		//   // let old_row_data = row;
		//   let old_row_data_f = this.tableDataCatch.find(item => {
		//     return item.id == row.id;
		//   });
		//   let old_row_data = deepClone(old_row_data_f); //深拷贝

		//   // let old_row_data = Object.assign(old_row_data_f,{});//浅拷贝
		//   // let old_row_data_index = this.tableDataCatch.indexOf(item=>{
		//   //   return item.id == row.id;
		//   // });
		//   // let old_row_data = this.tableDataCatch[old_row_data_index];
		//   // .map(function (item) { //接收新数组
		//   //   return item * item;
		//   // });
		//   console.log('old_row_data', old_row_data);
		//   // this.tableData
		//   this.$set(this.tableData, index, old_row_data);
		//   // this.$forceUpdate();
		// },
		//保存
		// saveAction(event, row, index) {
		//   event.stopPropagation();
		//   console.log('saveAction', row);
		//   this.postData(this.$urlConfig.WhPlRecUpdate, [row], () => {
		//     if (row.id) {
		//       //不刷新数据，仅改变编辑状态
		//       row.dataFlag = null;
		//       this.$set(this.tableData, index, row);
		//       this.$set(this.tableDataCatch, index, row);

		//       // this.$alert('恭喜,编辑成功！', this.$t('cc62f4bf31d661e3'), {
		//       //   type: 'success',
		//       //
		//       // });
		//       this.$message.success('编辑成功！');
		//     } else {
		//       this.initData();
		//       // this.$alert('恭喜,新增成功！', this.$t('cc62f4bf31d661e3'), {
		//       //   type: 'success',
		//       //
		//       // });
		//       this.$message.success('新增成功！');
		//     }
		//   });
		// },
		//仓库费用重算
		feeResetAction(event, row) {
			// event.stopPropagation();
			console.log('feeResetAction', row);
			if (!this.filterData.userId) {
				this.$message.warning(this.$t('i18nn_5184f7a4033b4969'));
				return;
			}
			// if (!this.filterData.whNo) {
			// 	this.$message.warning(this.$t('i18nn_1118edcaeb4c3679'));
			// 	return;
			// }
			// let startTime = '';
			// let endTime = '';
			// if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
			// 	startTime = this.filterData.daterange[0];
			// 	endTime = this.filterData.daterange[1];
			// } else {
			// 	this.$message.warning(this.$t('i18nn_cffd7c9783c11047'));
			// 	return;
			// }

			// let parm = {
			// 	userId: this.filterData.userId, //"用户Id",
			// 	feeDateStart: startTime, //this.$t('i18nn_9144ddc68d05d528'),
			// 	feeDateEnd: endTime ,//this.$t('i18nn_8b5d709462c8e71e'),
			// 	whNo:this.filterData.whNo,
			// 	// "goodsSku":row.goodsSku,//"商品SKU(不必填)"
			// };
			
			
			
			let filterData = Object.assign(
				{},
				this.pageFilterData()
			);
			
			this.postData(this.$urlConfig.WhFeeRecordReset + '/' + this.filterData.userId, filterData, () => {
				this.initData();
				//  this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
				//   type: 'success',
				//
				// });
				this.$message.success(this.$t('9f30371831a98237'));
				// if(row.id){
				//   row.dataFlag = '';
				//   this.$alert('恭喜,编辑成功！', this.$t('cc62f4bf31d661e3'), {
				//     type: 'success',
				//
				//   });
				// } else {
				//   this.initData();
				//   this.$alert('恭喜,新增成功！', this.$t('cc62f4bf31d661e3'), {
				//     type: 'success',
				//
				//   });
				// }
			});
		},

		//删除
		// delAction(event, row) {
		//   event.stopPropagation();
		//   console.log('delAction', row);
		//   let parm = [];

		//   if (!!row) {
		//     //单条
		//     parm = [row.id];
		//   } else {
		//     //多条
		//     let dataList = this.multipleSelection;
		//     if (dataList.length < 1) {
		//       this.$message.warning(this.$t('7b80e66b535a3732'));
		//       return;
		//     }

		//     let dataListParm = dataList.map(v => v.id);
		//     parm = dataListParm;
		//     // console.log('dataListParm', dataListParm);
		//     // let dataParm = {
		//     //   ids: dataListParm
		//     // };
		//   }

		//   this.postData(this.$urlConfig.WhGoodsDetDel, parm, () => {
		//     this.initData();
		//     //  this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
		//     //   type: 'success',
		//     //
		//     // });
		//     this.$message.success(this.$t('9f30371831a98237'));
		//     // if(row.id){
		//     //   row.dataFlag = '';
		//     //   this.$alert('恭喜,编辑成功！', this.$t('cc62f4bf31d661e3'), {
		//     //     type: 'success',
		//     //
		//     //   });
		//     // } else {
		//     //   this.initData();
		//     //   this.$alert('恭喜,新增成功！', this.$t('cc62f4bf31d661e3'), {
		//     //     type: 'success',
		//     //
		//     //   });
		//     // }
		//   });
		// },
		changCus(data) {
			console.log('changCus', data);
			// this.filterData.cusNo = data.cusNo;
			this.filterData.userId = data.userId;
			// userId:'',
			this.initData();
		},
		changWhNo(data) {
			console.log('changWhNo', data);
			this.filterData.whNo = data.code;
			// this.filterData.userId = data.userId;
			this.initData();
		},
		//分页的筛选项数据
		pageFilterData() {
			console.log('daterange', this.filterData.daterange);
			let startTime = '';
			let endTime = '';
			if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
				startTime = this.filterData.daterange[0];
				endTime = this.filterData.daterange[1];
			} else {
				this.$message.warning(this.$t('i18nn_cffd7c9783c11047'));
				return;
			}

			return {
				// states: this.filterData.status ? this.filterData.status : null,
				// plNo: this.filterData.plNo ? this.filterData.plNo : null,
				userId: this.filterData.userId ? this.filterData.userId : null,
				feeDateStart: startTime ? startTime : null,
				feeDateEnd: endTime ? endTime : null,
				keyword: this.filterData.keyword ? this.filterData.keyword : null,
				whNo: this.filterData.whNo ? this.filterData.whNo : null,
				clacType : this.filterData.chargeBizType ? this.filterData.chargeBizType : null,
				// packageNo: this.filterData.packageNo ? this.filterData.packageNo : null,
				// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null
				// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
			};
		},

		//请求分页数据
		getPageData() {
			let filterData = Object.assign(
				{
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size //当前页显示数目
				},
				this.pageFilterData()
			);

			this.loading_load = true;

			this.$http
				.put(this.$urlConfig.WhFeeRecordPageList, filterData)
				.then(({ data }) => {
					console.log('列表，请求成功');
					console.log(data);
					
					this.loading_load = false;
					if (200 == data.code) {
						//表格显示数据
						this.tableData = data.rows;
						//取消编辑时调用
						//缓存，待编辑的数据
						// this.tableDataCatch = data.rows;//直接赋值为浅拷贝 concat也是浅拷贝
						// this.tableDataCatch = deepClone(data.rows);
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
						// this.$message.success('订单列表，请求成功');
					} else {
						this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
					}
				})
				.catch(error => {
					console.log(error);
					console.log('列表，请求失败');
					this.$message.error(this.$t('hytxs0000030'));
					this.loading_load = false;
				});
		},

		//提交信息
		postData(url, formData, callBack) {
			// let _this = this;
			this.loading = true;
			// formData.state = formData.state ? '1' : '0';

			this.$http
				.put(url, formData)
				.then(({ data }) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						// this.dialogAddFormVisible = false;
						// this.initData();
						callBack();
					} else {
						if (!data.msg) {
							data.msg = this.$t('dbe331ab679cd67f');
						}
						this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					}
				})
				.catch(error => {
					console.log(error);
					console.log(this.$t('i18nn_a7d2e953195a5588'));
					this.loading = false;
					this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
						type: 'warning'
					});
				});
		},
		// getWhNoData() {
		// 	this.$http
		// 		.get(this.$urlConfig.whNoPageList)
		// 		.then(({ data }) => {
		// 			console.log('查询，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.rows) {
		// 				this.selectOption.wh_no = data.rows;
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = '查询仓库失败,请重试';
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log(this.$t('i18nn_323ee425eca7208c'));
		// 			this.$message.error('查询仓库失败,请重试！');
		// 		});
		// }
		//查询数据字典
		// getDicData() {
		// 	// let _this = this;
		// 	// console.log(keyword);
		
		// 	// this.loading_load = true;
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, ['wh_charge_biz_type'])
		// 		.then(({ data }) => {
		// 			console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.data) {
		// 				this.selectOption.wh_charge_biz_type = data.data['wh_charge_biz_type'];
		// 				// this.selectOption.wh_vol_unit = data.data["wh_vol_unit"];
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = this.$t('hytxs0000032');
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('查询数据字典接口，请求失败');
		// 			this.$message.error(this.$t('hytxs0000033'));
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
// /deep/ .el-table__body {
// 	.el-input {
// 		input {
// 			padding-left: 5px;
// 			padding-right: 5px;
// 		}
// 	}
// 	.el-input-number {
// 		width: 90px;
// 		.el-input__inner {
// 			text-align: left;
// 		}
// 	}
// }
</style>
